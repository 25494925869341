import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaWhatsapp } from "react-icons/fa";

export default function PeriodoIntegral() {

    const title = 'Período Integral';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <section className="block-green">
                    <div className="container-fluid p-5">
                        <div className="row animate__animated animate__bounceInUp animate__delay-01s">
                            <div className="col-md-12">
                                <h1 className="text-white text-uppercase mb-5 animate__animated animate__bounceInDown">{title}</h1>
                            </div>
                            <div className="col-md-5 mb-4">
                                <p className="f-26 text-white text-600 mb-3 pb-3 text-justify"></p>
                                <p className="f-26 text-white text-600 text-justify"></p>
                            </div>
                            <div className="col-md-7">
                                <p className="f-30 text-white text-600 mb-0 text-center">HORÁRIO:</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">07h30 às 19h</p>

                                <p className="f-30 text-white text-600 mb-0 text-center mt-5">REFEIÇÕES:</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Colação;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Almoço;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Lanche da tarde;</p>
                                <p className="f-24 text-white text-600 mb-3 text-center">Jantar;</p>
                                
                            </div>
                            <div className="col-md-12 text-right mt-3 animate__animated animate__bounceInDown">
                                <a href="https://wa.me/5524993197918" target="_blank" className="float-whatsapp">
                                    <div>Quero mais informações!</div>
                                    <div className="icon-whatsapp"><FaWhatsapp color="#b4cd19" /></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </section>
            <Footer />
        </>
    );
}