import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaWhatsapp } from "react-icons/fa";

export default function PropostaPedagogica() {

    const title = 'Proposta Pedagógica';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <section className="block-orange">
                    <div className="container-fluid p-5">
                        <div className="row justify-content-center pb-4">
                            <div className="col-md-12">
                                <h1 className="text-white text-uppercase mb-5 animate__animated animate__bounceInDown">{title}</h1>
                            </div>
                            <div className="col-md-6">
                                <div className="animate__animated animate__bounceInUp">
                                    <p className="f-26 text-white text-600 pb-3 text-justify">Acreditamos em uma educação que prepara o aluno para vida através de uma formação que visa o seu desenvolvimento integral, formando em nossa escola alunos relevantes e efetivos para nossa sociedade. </p>
                                    <p className="f-26 text-white text-600 pb-3 text-justify">Entendemos que todo ser humano anseia por uma verdade, e para nós essa verdade é bem definida na Palavra de Deus, a Bíblia. Nela estão contidas todas as verdades que o aluno precisa para atingir sua formação e, essa verdade bem definida, é transmitida pelo professor.</p>
                                    <p className="f-26 text-white text-600 pb-3 text-justify">Nossa linha de aprendizado é a cognitiva-interacionista que acredita que o aluno precisa interagir com a realidade, mas é importante que o professor transmita o conhecimento, considerando a maturidade e o desenvolvimento do aluno para entendê-la.</p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="animate__animated animate__bounceInUp">
                                    <p className="f-26 text-white text-600 pb-3 text-justify">Para alcançar o aprendizado é preciso perceber, compreender e refletir sobre os objetos do conhecimento estudado.</p>
                                    <p className="f-26 text-white text-600 pb-3 text-justify">Para que esse aprendizado se torne significativo e rico aplicamos a Pedagogia de Projetos que é uma forma de organização curricular onde os alunos são instigados a explorar a realidade  através das relações das áreas do conhecimento. Ela tem como objetivo educar através da experiência, em uma relação em que o professor atua como orientador e o aluno parte integrante no processo de aprendizagem. Com isso, buscamos estimular um processo de ensino-aprendizagem significativo e favorecer aos alunos o desenvolvimento das habilidades necessárias para sua formação integral.</p>
                                </div>
                            </div>
                            <div className="col-md-12 text-right mt-3 animate__animated animate__bounceInDown">
                                <a href="https://wa.me/5524993197918" target="_blank" className="float-whatsapp">
                                    <div>Quero mais informações!</div>
                                    <div className="icon-whatsapp"><FaWhatsapp color="#f7a61a" /></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </section>
            <Footer />
        </>
    );
}