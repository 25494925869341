import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

export default function Page404() {

    const title = 'Página não encontrada';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <PageHeader title={title} />
                <section>
                    <div className="container">
                        <div className="row animate__animated animate__bounceInUp animate__delay-04s">
                            <div className="col-md-8 m-auto">
                            <div className="text-center">
                                <div className="error_txt">404</div>
                                <h5 className="text_danger mb-4">Oops! {title}</h5> 
                                <Link to="/" className="btn btn-outline-black">IR PARA HOME</Link>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    );
}