import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaWhatsapp } from "react-icons/fa";

import mackenzie from '../../assets/images/logo_mackenzie.png';

export default function EducacaoFundamental() {

    const title = 'Educação Fundamental I';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <section className="block-orange">
                    <div className="container-fluid p-5">
                        <div className="row animate__animated animate__bounceInUp animate__delay-01s">
                            <div className="col-md-12">
                                <h1 className="text-white text-uppercase mb-5 animate__animated animate__bounceInDown">{title}</h1>
                            </div>
                            <div className="col-md-5 mb-4">
                                <p className="f-26 text-white text-600 mb-3 pb-3 text-justify">Nossa proposta para Ensino Fundamental é continuar com a riqueza da aplicação da Pedagogia de Projeto que proporciona ao aluno construir o seu conhecimento a proporção que o professor vai revelando a verdade que está na Bíblia, nossa base de ensino. Essa base está ligada com os objetivos da BNCC para cada faixa etária. </p>
                                <p className="f-26 text-white text-600 mb-3 pb-3 text-justify">Para enriquecer nosso processo de ensino e aprendizado contamos com a parceria do Sistema de Ensino Mackenzie, que oferece um material didático com uma cosmovisão cristã, presente no mercado há décadas, reconhecido por uma educação de excelência e vanguarda. </p>
                                <p className="f-26 text-white text-600 text-justify">Nosso objetivo para os nossos alunos do Ensino Fundamental é que recebam um ensinamento baseado na verdade que é a Bíblia, desenvolvendo os objetivos estabelecidos pela BNCC, através de Temas geradores que serão conectados ao Sistema de Ensino Mackenzie.</p>
                            </div>
                            <div className="col-md-7">
                                <p className="f-30 text-white text-600 mb-0 text-center">NOSSAS TURMAS:</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">1° ano;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">2° ano;</p>

                                <p className="f-30 text-white text-600 mb-0 text-center mt-5">AULAS EXTRAS:</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Educação Física;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Música;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Inglês;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Culinária;</p>

                                <p className="f-30 text-white text-600 mb-0 text-center mt-5">HORÁRIOS:</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Período Integral: 07h30 às 19h;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Período Parcial: 13h às 17h40</p>

                                <p className="f-30 text-white text-600 mb-0 text-center mt-5">ESCOLA PARCEIRA</p>
                                <a href="https://www.mackenzie.br/sistema-mackenzie-de-ensino" target="_blank">
                                    <img src={mackenzie} className="logo-mackenzie" />
                                </a>
                            </div>
                            <div className="col-md-12 text-right mt-3 animate__animated animate__bounceInDown">
                                <a href="https://wa.me/5524993197918" target="_blank" className="float-whatsapp">
                                    <div>Quero mais informações!</div>
                                    <div className="icon-whatsapp"><FaWhatsapp color="#f7a61a" /></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </section>
            <Footer />
        </>
    );
}