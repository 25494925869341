import React from 'react';
import './assets/css/global.css';
import './assets/css/styles.css';

import { AuthProvider } from './contexts/auth';
import { ParallaxProvider } from 'react-scroll-parallax';

import Routes from './routes';

function App() {
    return (
        <AuthProvider>
            <ParallaxProvider>
                <Routes />
            </ParallaxProvider>
        </AuthProvider>
    );
}

export default App;