import React from 'react';
import animateScrollTo from 'animated-scroll-to';
import { Link } from 'react-router-dom';
import { FaPhone, FaMapMarkerAlt, FaEnvelope, FaFacebook, FaInstagram, FaYoutube, FaAngleUp, FaWhatsapp } from "react-icons/fa";

import logo from '../assets/images/logo.png';
import nerdetcetera from '../assets/images/logo_nerdetcetera.png';
import pattern from '../assets/images/pattern_bg6.png';

export default function Footer() {
    
    function scrollToPosition() {
        animateScrollTo(0);
    }

    window.onscroll = function() {
        const header = document.querySelector("#header");
        const scrollup = document.querySelector(".scrollup");
        /* if (window.pageYOffset>=100) {
            header.classList.add("nav-fixed");
        } else {
            header.classList.remove("nav-fixed");
        } */

        if (window.pageYOffset>=200) {
            scrollup.classList.add("display-block");
        } else {
            scrollup.classList.remove("display-hide");
        }
    };

    return (
        <>
            <footer className="footer_dark bg_black background_bg bg_fixed bg_cover" style={{backgroundImage: `url(${pattern})`}}>
                <div className="top_footer">
                    <div className="container-fluid pl-5 pr-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="footer_logo mb-3">
                                    <Link to="/"><img src={logo} /></Link>
                                </div>
                                <ul className="contact_info contact_info_light list_none text-white mb-2">
                                    <li>
                                        <FaMapMarkerAlt /> Av. Benjamin Constant, 280 - Centro, Petrópolis, RJ, 25610-130
                                    </li>
                                    <li>
                                        <FaEnvelope /> contato@institutowesleyano.org
                                    </li>
                                    <li>
                                        <FaWhatsapp size={20} /> <a href="https://wa.me/5524993197918" target="_blank" className="text-white">(24) 99319-7918</a>
                                    </li>
                                    <li>
                                        <FaPhone /> (24) 2244-8226
                                    </li>

                                    <li>
                                        <Link to="/contato" className="btn btn-default btn-sm">ENTRE EM CONTATO</Link>
                                    </li>
                                </ul>
                                <ul className="list_none social_icons radius_social social_white social_style1">
                                    <li><a target="_blank" href="http://instagram.com/institutowesleyano"><FaInstagram size={18} /></a></li>
                                    <li><a target="_blank" href="http://facebook.com/institutowesleyano"><FaFacebook size={18} /></a></li>
                                    <li><a target="_blank" href="http://www.youtube.com/channel/UCm_8QSMMyPlK36bvicNkuCA"><FaYoutube size={22} /></a></li>
                                </ul>
                            </div>
                            <div className="col-md-6 text-right">
                                <ul className="list_none widget_links">
                                    <li>
                                        <Link to="/quem-nos-somos">QUEM NÓS SOMOS</Link>
                                    </li>
                                    <li>
                                        <Link to="/casarao-gernheim">CASARÃO GERNHEIM</Link>
                                    </li>
                                    <li>
                                        <Link to="/nossa-equipe">NOSSA EQUIPE</Link>
                                    </li>
                                    <li>
                                        <Link to="/confecionalidade">CONFECIONALIDADE</Link>
                                    </li>
                                    <li>
                                        <Link to="/proposta-pedagogica">PROPOSTA PEDAGÓGICA</Link>
                                    </li>
                                    <li>
                                        <Link to="/educacao-infantil">EDUCAÇÃO INFANTIL</Link>
                                    </li>
                                    <li>
                                        <Link to="/educacao-fundamental">ENSINO FUNDAMENTAL I</Link>
                                    </li>
                                    <li>
                                        <Link to="/periodo-integral">PERÍODO INTEGRAL</Link>
                                    </li>
                                    <li>
                                        <Link to="/projeto-social-semear">PROJETO SOCIAL SEMEAR</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom_footer bg_blue py-3">
                    <div className="container-fluid pl-5 pr-5">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <p className="copyright m-md-0 text-left">©2023 <strong>Instituto Wesleyano de Ensino</strong> - Todos os Direitos Reservados.</p>
                            </div>
                            <div className="col-md-6">
                                <p className="copyright m-md-0 text-right">Desenvolvido por <a style={{display:'inline'}} target="_blank" href="https://nerdetcetera.com"><img style={{marginLeft:5, height:24}} src={nerdetcetera} /></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a onClick={scrollToPosition} className="scrollup"><FaAngleUp /></a> 
        </>
    );
}