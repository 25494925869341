import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaWhatsapp } from "react-icons/fa";

export default function Confecionalidade() {

    const title = 'Confecionalidade';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <section className="block-red">
                    <div className="container-fluid p-5">
                        <div className="row animate__animated animate__bounceInUp animate__delay-01s">
                            <div className="col-md-12">
                                <h1 className="text-white text-uppercase mb-5 animate__animated animate__bounceInDown">{title}</h1>
                            </div>
                            <div className="col-md-6">
                                <p className="f-26 text-white text-600 text-justify">Nossa visão como escola é formarmos indivíduos que sejam relevantes e efetivos para sociedade, e para que isso aconteça acreditamos que esta formação precisa estar totalmente fundamentada na Palavra de Deus que é a Bíblia. </p>
                            </div>

                            <div className="col-md-6">
                                <p className="f-26 text-white text-600 text-justify">Acreditamos na influência de uma escola confessional para construção de uma sociedade saudável, visando a formação integral do aluno através de um aprendizado cognitivo baseado na Palavra de Deus que irá influenciar para o desenvolvimento espiritual, social e emocional.</p>
                            </div>

                            <div className="col-md-12 text-right mt-3 animate__animated animate__bounceInDown">
                                <a href="https://wa.me/5524993197918" target="_blank" className="float-whatsapp">
                                    <div>Quero mais informações!</div>
                                    <div className="icon-whatsapp"><FaWhatsapp color="#e07111" /></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </section>
            <Footer />
        </>
    );
}