import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

export default function ProcessoSeletivo() {

    const title = 'Processo Seletivo';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <PageHeader title={title} />
                <section className="bg_gray">
                    <div className="container">
                        <div className="row justify-content-center mb-4">
                            <div className="col-md-10 m-auto">
                                <div className="text-center animate__animated animate__bounceInUp">
                                    <p className="f-18">
                                        Atenção!<br />
                                        Informamos que devido a grande quantidade de currículos recebidos, estendemos o tempo de análise e seleção das inscrições que serão chamadas para a primeira fase do nosso Processo Seletivo.<br />
                                        Com estas, entraremos em contato até o dia 03/11. As candidatas que não forem contactadas até esta data ficarão com os cadastros para futuras oportunidades.
                                    </p>
                                    <Link to="/" className="btn btn-outline-black">IR PARA HOME</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    );
}