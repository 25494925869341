import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Parallax } from 'react-scroll-parallax';
import shape5 from '../../assets/images/shape5.png';
import shape7 from '../../assets/images/shape7.png';
import shape10 from '../../assets/images/shape10.png';
import shape27 from '../../assets/images/shape27.png';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import espaco_1 from '../../assets/images/espaco-1.jpg';
import espaco_2 from '../../assets/images/espaco-2.jpg';
import espaco_3 from '../../assets/images/espaco-3.jpg';
import espaco_4 from '../../assets/images/espaco-4.jpg';
import espaco_5 from '../../assets/images/espaco-5.jpg';

export default function EspacoStart() {

    const title = 'Espaço Start';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <PageHeader title={title} />
                <section className="who-we-are ptb-100 animate__animated animate__bounceInUp">
                    <div className="container" style={{display:'none'}}>
                        <div className="row">
                            <div className="col-md-12">
                            <Carousel
                                className="carousel-banner-home"
                                interval={6000}
                                transitionTime={400}
                                autoPlay={true}
                                infiniteLoop={true}
                                showArrows={true}
                                showIndicators={false}
                                showStatus={false}
                                showThumbs={false}
                                stopOnHover={false}
                                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                    hasPrev && (
                                        <a onClick={onClickHandler} title={label} className="carousel-control-prev">
                                            <FiChevronLeft />
                                        </a>
                                    )
                                }
                                renderArrowNext={(onClickHandler, hasNext, label) =>
                                    hasNext && (
                                        <a onClick={onClickHandler} title={label} className="carousel-control-next">
                                            <FiChevronRight />
                                        </a>
                                    )
                                }
                            >
                                <div className="banner_container">
                                    <img className="img-rounded-2" src={espaco_1} />
                                </div>
                                <div className="banner_container">
                                    <img className="img-rounded-2" src={espaco_2} />
                                </div>
                                <div className="banner_container">
                                    <img className="img-rounded-2" src={espaco_3} />
                                </div>
                                <div className="banner_container">
                                    <img className="img-rounded-2" src={espaco_4} />
                                </div>
                                <div className="banner_container">
                                    <img className="img-rounded-2" src={espaco_5} />
                                </div>
                            </Carousel>
                            </div>
                        </div>
                    </div>
                    <div className="ol_shape5">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-01s" y={[-30, 0]} tagOuter="figure">
                            <img src={shape5} />
                        </Parallax>
                    </div>
                    <div className="ol_shape7">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-02s" y={[160, 200]} tagOuter="figure">
                            <img src={shape7} />
                        </Parallax>
                    </div>
                    <div className="ol_shape10">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-03s" y={[30, 20]} tagOuter="figure">
                            <img src={shape10} />
                        </Parallax>
                    </div>
                    <div className="ol_shape27">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-04s" y={[160, 200]} tagOuter="figure">
                            <img src={shape27} />
                        </Parallax>
                    </div>
                </section>
                <section className="mt-0 pt-0">
                    <div className="container">
                        <div className="row animate__animated animate__bounceInUp animate__delay-04s">
                            <div className="col-md-8 m-auto">
                                <p className="f-17 mb-3 pb-3 text-justify">Aqui acontecem diariamente nossas devocionais. O devocional diário faz com que a criança desenvolva o hábito saudável de comunhão com Deus e compreenda o tamanho da sua importância.</p>
                                <p className="f-17 mb-3 pb-3 text-justify">Semanalmente realizamos nosso Culto Kids. Com uma linguagem adaptada ao universo infantil, muita alegria e em formato bem lúdico, nossas crianças aprendem com prazer a Palavra de Deus.</p>
                                <p className="f-17 mb-0 text-justify">Assim como Jesus ensinava através de histórias, nossa proposta é ministrar através de uma abordagem simples, desenvolver ensinamentos e temas relevantes e sociais que falam de amizade, respeito, amor, comunhão e o cuidado com a natureza e toda a criação.</p>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    );
}