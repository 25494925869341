import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function ProjetoSocialSemear() {

    const title = 'Projeto Social Semear';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <section className="block-blue">
                    <div className="container-fluid p-5">
                        <div className="row animate__animated animate__bounceInUp animate__delay-01s">
                            <div className="col-md-12">
                                <h1 className="text-white text-uppercase mb-5 animate__animated animate__bounceInDown">{title}</h1>
                            </div>
                            <div className="col-md-12 mb-4">
                                <p className="f-26 text-white text-600 mb-3 pb-3 text-justify">Nossa visão como escola é formar indivíduos efetivos e relevantes para a sociedade através de uma educação de qualidade baseada nos ensinamentos bíblicos. Entendendo a importância e relevância da nossa proposta, acreditamos que esta educação precisa estar ao alcance de todos.</p>
                                <p className="f-26 text-white text-600 text-justify">Através do Projeto Semear, em 2025 abriremos a oportunidade de 4 bolsas sociais para as turmas do 4º Período da Educação Infantil;1º, 2º e 3º ano do Ensino Fundamental. Quatro famílias terão a oportunidade de oferecer as suas crianças uma educação de excelência que contribuirá para que se tornem cidadãos efetivos e relevantes.</p>
                            </div>
                            
                            <div className="col-md-12 text-center animate__animated animate__bounceInDown">
                                <a className="btn-bigger" href="/manual-para-concessao-de-bolsas-assistenciais-2025.pdf" target="_blank">BAIXAR MANUAL DE CONCESSÃO DE BOLSAS DE ESTUDO ASSISTENCIAIS</a>
                            </div>
                            <div className="col-md-12 text-center animate__animated animate__bounceInDown">
                                <a className="btn-bigger" href="https://wa.me/5524993197918?text=Olá! Gostaria de ser um parceiro do Projeto Social Semear!" target="_blank">QUERO SER UM PARCEIRO DO PROJETO SOCIAL SEMEAR</a>
                            </div>
                        </div>
                    </div>
                    
                </section>
            <Footer />
        </>
    );
}