import React, { useState, useEffect, useRef } from 'react';
import { Parallax } from 'react-scroll-parallax';
import InputMask from "react-input-mask";
import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import api from '../../services/api';

import shape20 from '../../assets/images/shape20.png';
import shape21 from '../../assets/images/shape21.png';
import shape23 from '../../assets/images/shape23.png';
import shape24 from '../../assets/images/shape24.png';
import shape25 from '../../assets/images/shape25.png';
import shape27 from '../../assets/images/shape27.png';
import shape28 from '../../assets/images/shape28.png';

export default function InscricaoEstagiaria() {

    const formacao_ref = useRef(null);
    const foto_ref = useRef(null);

    const [formacao, setFormacao] = useState('');
    const [formacao_file, setFormacaoFile] = useState('');
    const [foto_file, setFotoFile] = useState('');

    const [filhos, setFilhos] = useState('');
    function onFormacao(event){
        setFormacao(event.target.value);
    }
    function onFilhos(event){
        setFilhos(event.target.value);
    }
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [birthday, setBirthday] = useState('');
    const [estado_civil, setEstadoCivil] = useState('');

    const [quantos, setQuantos] = useState('');
    const [idades, setIdades] = useState('');
    const [address, setAddress] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');

    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedin, setLinkedIn] = useState('');
    
    async function onFileFormacao(event) {
        var reader = new FileReader();
        reader.onloadend = function() {
            setFormacaoFile(reader.result);
        }
        reader.readAsDataURL(event.target.files[0]);
    }

    async function onFileFoto(event) {
        var reader = new FileReader();
        reader.onloadend = function() {
            setFotoFile(reader.result);
        }
        reader.readAsDataURL(event.target.files[0]);
    }

    function reset(){
        setFormacao('');
        setFormacaoFile('');
        setFotoFile('');
        setFilhos('');
        setName('');
        setEmail('');
        setPhone('');
        setBirthday('');
        setEstadoCivil('');
        setQuantos('');
        setIdades('');
        setAddress('');
        setNeighborhood('');
        setCity('');
        setState('');
        setFacebook('');
        setInstagram('');
        setLinkedIn('');

        setLoading(false);
        setError(false);
        setErrorMessage('');
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState(false);

    async function submitContact(e){
        e.preventDefault();

        setLoading(true);
        setSuccess(false);
        try{
            if(
                formacao==="" ||
                name==="" ||
                email==="" ||
                phone==="" ||
                birthday==="" ||
                estado_civil==="" ||
                address==="" ||
                neighborhood==="" ||
                city==="" ||
                state===""
            ){
                setLoading(false);
                setError(true);
                setErrorMessage("Preencha os campos obrigatórios!");
                return;
            }
            if(formacao==="yes"){
                if(formacao_file===""){
                    setLoading(false);
                    setError(true);
                    setErrorMessage("Preencha os campos obrigatórios!");
                    return;
                }
            }
            if(foto_file===""){
                setLoading(false);
                setError(true);
                setErrorMessage("Preencha os campos obrigatórios!");
                return;
            }
            if(filhos==="yes"){
                if(quantos==="" || idades===""){
                    setLoading(false);
                    setError(true);
                    setErrorMessage("Preencha os campos obrigatórios!");
                    return;
                }
            }

            const respose = await api.post('/wesleyano-inscricao?id=estagiaria', {
                formacao,
                name,
                email,
                phone,
                birthday,
                estado_civil,
                filhos,
                address,
                neighborhood,
                city,
                state,
                quantos,
                idades,
                facebook,
                instagram,
                linkedin,
                formacao_file,
                foto_file
            });
            
            setSuccess(true);
            setSuccessMessage("Informações enviadas com sucesso!");
            reset();

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage("Erro ao enviar informações! Tente novamente mais tarde!");
        }
    }

    const title = 'Inscrição para: Estagiária';
    const title_html = 'Inscrição para:<br />Estagiária';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
            <PageHeader title={title_html} />
            <section className="staggered-animation-wrap">
                <div className="container">
                    <div className={`row ${ loading ? 'loading-sending' : '' }`}>
                        <div className="col-md-8 m-auto animate__animated animate__bounceInUp animate__delay-01s">
                            <form onSubmit={submitContact}>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <div>
                                            <label>Está cursando do 1º ao 6º período em curso de nível superior, em licenciatura em pedagogia? *</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="formacao" id="formacao_yes" onChange={onFormacao} value="yes" checked={formacao==="yes"} />
                                            <label className="form-check-label" htmlFor="formacao_yes">
                                                SIM
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="formacao" id="formacao_no" onChange={onFormacao} value="no" checked={formacao==="no"} />
                                            <label className="form-check-label" htmlFor="formacao_no">
                                                NÃO
                                            </label>
                                        </div>
                                    </div>
                                    {formacao==="yes" &&
                                    <div className="form-group col-12">
                                        <label htmlFor="formacao_file">Por favor, anexe sua declaração de matrícula. *</label>
                                        <input type="file" className="form-control-file" id="formacao_file" ref={formacao_ref} onChange={onFileFormacao} />
                                    </div>}
                                    {formacao==="no" &&
                                    <div className="form-group col-12">
                                        <div className="alert alert-danger">
                                        Agradecemos o interesse, porém não podemos dar continuidade a inscrição devido a não preencher o requisito FORMAÇÃO.
                                        </div>
                                    </div>}
                                    <div className="form-group col-12">
                                        <label htmlFor="foto_file">Anexar sua Foto. *</label>
                                        <input type="file" className="form-control-file" id="foto_file" ref={foto_ref} onChange={onFileFoto} />
                                    </div>
                                    <div className="form-group mt-3 mb-0 col-12">
                                        <label>Preencha os dados abaixo:</label>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input className="form-control" placeholder="Nome completo *" value={name} onChange={e => setName(e.target.value)} type="text" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input className="form-control" placeholder="E-mail *" value={email} onChange={e => setEmail(e.target.value)} type="email" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <InputMask className="form-control" mask="(99) 99999-9999" placeholder="Celular *" value={phone} onChange={e => setPhone(e.target.value)} type="tel" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <InputMask className="form-control" mask="99/99/9999" placeholder="Data de Nascimento *" value={birthday} onChange={e => setBirthday(e.target.value)} type="tel" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input className="form-control" placeholder="Estado Civil *" value={estado_civil} onChange={e => setEstadoCivil(e.target.value)} type="text" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div>
                                            <label className="mb-0">Possui filhos? *</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="filhos" id="filhos_yes" onChange={onFilhos} value="yes" checked={filhos==="yes"} />
                                            <label className="form-check-label" htmlFor="filhos_yes">
                                                SIM
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="filhos" id="filhos_no" onChange={onFilhos} value="no" checked={filhos==="no"} />
                                            <label className="form-check-label" htmlFor="filhos_no">
                                                NÃO
                                            </label>
                                        </div>
                                    </div>
                                    {filhos==="yes" &&
                                    <>
                                        <div className="form-group col-md-6">
                                            <input className="form-control" placeholder="Quantos? *" value={quantos} onChange={e => setQuantos(e.target.value)} type="text" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input className="form-control" placeholder="Idades *" value={idades} onChange={e => setIdades(e.target.value)} type="text" />
                                        </div>
                                    </>}

                                    <div className="form-group col-md-6">
                                        <input className="form-control" placeholder="Endereço *" value={address} onChange={e => setAddress(e.target.value)} type="text" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input className="form-control" placeholder="Bairro *" value={neighborhood} onChange={e => setNeighborhood(e.target.value)} type="text" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input className="form-control" placeholder="Cidade *" value={city} onChange={e => setCity(e.target.value)} type="text" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <select className="form-control" value={state} onChange={e => setState(e.target.value)}>
                                            <option value="">Estado *</option>
                                            <option value="AC">Acre</option>
                                            <option value="AL">Alagoas</option>
                                            <option value="AP">Amapá</option>
                                            <option value="AM">Amazonas</option>
                                            <option value="BA">Bahia</option>
                                            <option value="CE">Ceará</option>
                                            <option value="DF">Distrito Federal</option>
                                            <option value="ES">Espírito Santo</option>
                                            <option value="GO">Goiás</option>
                                            <option value="MA">Maranhão</option>
                                            <option value="MT">Mato Grosso</option>
                                            <option value="MS">Mato Grosso do Sul</option>
                                            <option value="MG">Minas Gerais</option>
                                            <option value="PA">Pará</option>
                                            <option value="PB">Paraíba</option>
                                            <option value="PR">Paraná</option>
                                            <option value="PE">Pernambuco</option>
                                            <option value="PI">Piauí</option>
                                            <option value="RJ">Rio de Janeiro</option>
                                            <option value="RN">Rio Grande do Norte</option>
                                            <option value="RS">Rio Grande do Sul</option>
                                            <option value="RO">Rondônia</option>
                                            <option value="RR">Roraima</option>
                                            <option value="SC">Santa Catarina</option>
                                            <option value="SP">São Paulo</option>
                                            <option value="SE">Sergipe</option>
                                            <option value="TO">Tocantins</option>
                                        </select>
                                    </div>

                                    <div className="form-group mt-3 mb-0 col-12">
                                        <label>Redes Sociais:</label>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <input className="form-control" placeholder="Facebook" value={facebook} onChange={e => setFacebook(e.target.value)} type="text" />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <input className="form-control" placeholder="Instagram" value={instagram} onChange={e => setInstagram(e.target.value)} type="text" />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <input className="form-control" placeholder="LinkedIn" value={linkedin} onChange={e => setLinkedIn(e.target.value)} type="text" />
                                    </div>

                                    <div className="col-lg-12">
                                        <button type="submit" className="btn btn-default" name="submit" disabled={formacao==="no" ? true : false}>ENVIAR INSCRIÇÃO</button>
                                    </div>
                                    <div className="col-lg-12">
                                        <p className="mt-2 mb-2">* Campos obrigatórios</p>
                                    </div>
                                    <div className="col-lg-12">
                                        {success &&
                                        <div className="alert alert-success">
                                            Agradecemos sua inscrição!<br /><br />
                                            Entraremos em contato até o dia 31/03/2022, convidando as candidatas selecionadas para a próxima fase.<br /><br />
                                            Caso não receba nossa ligação até esta data, sua inscrição ficará em nosso banco de dados para futuras oportunidades.
                                        </div>}
                                        {error &&
                                        <div className="alert alert-danger">
                                            {error_message}
                                        </div>}
                                    </div>
                                </div>
                            </form>	
                        </div>
                    </div>
                </div>
                <div className="ol_shape20">
                    <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-04s" y={[-30, 20]} tagOuter="figure">
                        <img src={shape20} />
                    </Parallax>
                </div>
                <div className="ol_shape21">
                    <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-04s" y={[30, 20]} tagOuter="figure">
                        <img src={shape21} />
                    </Parallax>
                </div>
                <div className="ol_shape23">
                    <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-03s" y={[30, 20]} tagOuter="figure">
                        <img src={shape23} />
                    </Parallax>
                </div>
                <div className="ol_shape24">
                    <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-04s" y={[-30, 20]} tagOuter="figure">
                        <img src={shape24} />
                    </Parallax>
                </div>
                <div className="ol_shape25">
                    <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-04s" y={[30, 20]} tagOuter="figure">
                        <img src={shape25} />
                    </Parallax>
                </div>
                <div className="ol_shape27">
                    <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-03s" y={[-30, 20]} tagOuter="figure">
                        <img src={shape27} />
                    </Parallax>
                </div>
                <div className="ol_shape28">
                    <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-03s" y={[-30, 20]} tagOuter="figure">
                        <img src={shape28} />
                    </Parallax>
                </div>
            </section> 
            
            <Footer />
        </>
        
    );
}