import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import casarao from '../../assets/images/casarao.jpg';

export default function CasaraoGernheim() {

    const title = 'Casarão Gernheim';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <section className="block-red">
                    <div className="container-fluid p-5">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="text-white text-uppercase mb-5 animate__animated animate__bounceInDown">{title}</h1>
                            </div>
                            <div className="col-md-7 pr-5 animate__animated animate__bounceInUp animate__delay-01s">
                                <img src={casarao} className="img-rounded" />
                            </div>
                            <div className="col-md-5 animate__animated animate__bounceInUp animate__delay-02s">
                                <p className="f-26 text-white text-600 mb-3 pb-3 text-center">No dia 19 de agosto de 1855, o Casal Robert e Sarah Kalley começava nas dependências do Casarão Gernheim (Lar querido) a primeira Escola Bíblica em terras brasileiras. Crianças, jovens e adultos foram ensinados e evangelizados nas salas desta linda casa.</p>
                                <p className="f-26 text-white text-600 mb-3 pb-3 text-center">Pela primeira vez em nossa história, negros foram acolhidos pela Graça do evangelho que não faz distinção de nenhum ser humano. Desde o mais simples até o Imperador do Brasil frequentaram as salas desta casa e aqui foram instruídos a respeito dos princípios divinos.</p>
                                <p className="f-26 text-white text-600 mb-0 text-center">Temos o privilégio e o desafio de dar continuidade histórica a este legado de evangelização através do ensino e ainda desfrutar da elegância e relevância que Gernheim, desde 1848, nos oferece.</p>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    );
}