import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import Home from './pages/Home';
import QuemSomos from './pages/QuemSomos';
import Termos from './pages/Termos';
import Politica from './pages/Politica';
import CasaraoGernheim from './pages/CasaraoGernheim';
import EspacoStart from './pages/EspacoStart';
import Contato from './pages/Contato';
import PropostaPedagogica from './pages/PropostaPedagogica';
import EducacaoInfantil from './pages/EducacaoInfantil';
import SalaConvivencia from './pages/SalaConvivencia';
import AtividadesEspacos from './pages/AtividadesEspacos';
import ProcessoSeletivo from './pages/ProcessoSeletivo';
import InscricaoEstagiaria from './pages/InscricaoEstagiaria';
import Encerrado from './pages/Encerrado';
import Confecionalidade from './pages/Confecionalidade';
import EducacaoFundamental from './pages/EducacaoFundamental';
import PeriodoIntegral from './pages/PeriodoIntegral';
import NossaEquipe from './pages/NossaEquipe';
import ProjetoSocialSemear from './pages/ProjetoSocialSemear';

import ScrollToTop from './components/ScrollToTop';
import Page404 from './pages/Page404';

export default function Routes() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/quem-nos-somos" component={QuemSomos} />
                <Route path="/casarao-gernheim" component={CasaraoGernheim} />
                <Route path="/espaco-start" component={EspacoStart} />
                <Route path="/contato" component={Contato} />
                <Route path="/proposta-pedagogica" component={PropostaPedagogica} />
                <Route path="/educacao-infantil" component={EducacaoInfantil} />
                <Route path="/educacao-fundamental" component={EducacaoFundamental} />
                <Route path="/periodo-integral" component={PeriodoIntegral} />
                <Route path="/sala-de-convivencia" component={SalaConvivencia} />
                <Route path="/atividades-e-espacos" component={AtividadesEspacos} />
                <Route path="/encerrado" component={Encerrado} />
                <Route path="/termos" component={Termos} />
                <Route path="/privacidade" component={Politica} />
                <Route path="/processo-seletivo" component={ProcessoSeletivo} />
                <Route path="/inscricao-estagiaria" component={InscricaoEstagiaria} />
                <Route path="/confecionalidade" component={Confecionalidade} />
                <Route path="/nossa-equipe" component={NossaEquipe} />
                <Route path="/projeto-social-semear" component={ProjetoSocialSemear} />

                <Redirect path="/" exact to="/quem-somos" />
                <Redirect path="/processo-seletivo" to="/encerrado" />
                <Redirect path="/inscricao-professor-de-educacao-infantil" to="/encerrado" />
                <Redirect path="/inscricao-auxiliar-de-educacao-infantil" to="/encerrado" />
                <Redirect path="/inscricao-secretaria" to="/encerrado" />
                <Redirect path="/inscricao-bercarista" to="/encerrado" />
                <Redirect path="/inscricao-auxiliar-de-servicos-gerais" to="/encerrado" />
                <Redirect path="/inscricao-estagiaria" to="/encerrado" />
                <Route component={Page404} />
            </Switch>
        </BrowserRouter>
    );
}