import React, { useState} from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FaPhone, FaFacebook, FaInstagram, FaYoutube, FaBars, FaWhatsapp } from "react-icons/fa";

import logo from '../assets/images/logo.png';

export default function Header(props) {

    const [menu, setMenu] = useState(false);
    function openMenu(){
        menu ? setMenu(false) : setMenu(true);
    }

    const { no_menu } = props;

    return (
        <header className="header_wrap dark_skin bg-white" id="header">
            <div className="top-header bg_blue light_skin border-0">
                <div className="container-fluid pl-5 pr-5">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <ul className="contact_detail list_none text-center text-md-left">
                                <li><strong><a href="https://wa.me/5524993197918" target="_blank"><FaWhatsapp size={18} />(24) 99319-7918</a> | <FaPhone size={14} />(24) 2244-8226</strong></li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-wrap align-items-center justify-content-md-end justify-content-center mt-2 mt-md-0">
                                <ul className="list_none social_icons social_white text-center text-md-right">
                                    <li><a target="_blank" href="http://instagram.com/institutowesleyano"><FaInstagram size={20} /></a></li>
                                    <li><a target="_blank" href="http://facebook.com/institutowesleyano"><FaFacebook size={20} /></a></li>
                                    <li><a target="_blank" href="http://www.youtube.com/channel/UCm_8QSMMyPlK36bvicNkuCA"><FaYoutube size={24} /></a></li>
                                </ul>
                                {/*<ul className="list_none header_list border_list ml-1">
                                    <li><Link to="/processo-seletivo" className="btn btn-outline-white btn-sm">Processo Seletivo</Link></li>
                                </ul>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!no_menu &&
            <div className="header_menu">
                <div className="container-fluid pl-5 pr-5">
                    <nav className="navbar navbar-expand-lg"> 
                        <Link className="navbar-brand" to="/">
                            <img className="logo" src={logo} />
                        </Link>
                        <button className="navbar-toggler" type="button" onClick={openMenu}>
                            <FaBars color="#3390b2" />
                        </button>
                        <div className={`collapse navbar-collapse justify-content-end ${menu ? 'show': ''}`} id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li>
                                    <NavLink className="nav-link" activeClassName="active" to="/quem-nos-somos">QUEM NÓS SOMOS</NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" activeClassName="active" to="/casarao-gernheim">CASARÃO GERNHEIM</NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" activeClassName="active" to="/nossa-equipe">NOSSA EQUIPE</NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" activeClassName="active" to="/confecionalidade">CONFECIONALIDADE</NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" activeClassName="active" to="/proposta-pedagogica">PROPOSTA PEDAGÓGICA</NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" activeClassName="active" to="/educacao-infantil">EDUCAÇÃO INFANTIL</NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" activeClassName="active" to="/educacao-fundamental">ENSINO FUNDAMENTAL I</NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" activeClassName="active" to="/periodo-integral">PERÍODO INTEGRAL</NavLink>
                                </li>
                                <li>
                                    <NavLink className="nav-link" activeClassName="active" to="/projeto-social-semear">PROJETO SOCIAL SEMEAR</NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>}
        </header>
    );
}