import React, { useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';

import shape13 from '../../assets/images/shape13.png';
import shape24 from '../../assets/images/shape24.png';
import shape30 from '../../assets/images/shape30.png';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

export default function SalaConvivencia() {
    const title = 'Sala Convivência';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <PageHeader title={title} />
                <section className="sala_convivencia">
                    <div className="container">
                        <div className="row animate__animated animate__bounceInUp animate__delay-01s">
                            <div className="col-md-8 m-auto">
                                <p className="f-17 mb-3 pb-3 text-justify">Pensando nas potencialidades do indivíduo preparamos um espaço dinâmico para que nossos alunos tenham momentos de exploração, construção e socialização.</p>
                                <p className="f-17 mb-3 pb-3 text-justify">Em nossa <strong className="text_blue">SALA DE CONVIVÊNCIA</strong> disponibilizamos jogos pedagógicos, projetor, tablado para apresentações internas, fantoches e fantasias para potencializar o imaginário e o desenvolvimento que o lúdico proporciona através de estímulos.</p>
                                <p className="f-17 mb-0 text-justify">Entendemos que o contato com os livros e as histórias auxilia no desenvolvimento psicomotor, cognitivo, intelectual, além de contribuir para melhorias no vocabulário, na fala e no rendimento escolar, por tais razões, na Sala de Convivência encontra-se também uma rica mini biblioteca.</p>
                            </div>
                        </div>
                    </div>
                    <div className="ol_shape30">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-01s" y={[-30, 20]} tagOuter="figure">
                            <img src={shape30} />
                        </Parallax>
                    </div>
                    <div className="ol_shape13">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-02s" y={[30, 20]} tagOuter="figure">
                            <img src={shape13} />
                        </Parallax>
                    </div>
                    <div className="ol_shape24">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-03s" y={[30, 20]} tagOuter="figure">
                            <img src={shape24} />
                        </Parallax>
                    </div>
                </section>
            <Footer />
        </>
    );
}