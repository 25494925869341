import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import base from '../../assets/images/base-equipe.png';
import equipe_1 from '../../assets/images/equipe_1.png';
import equipe_2 from '../../assets/images/equipe_2.png';
import equipe_3 from '../../assets/images/equipe_3.png';
import equipe_4 from '../../assets/images/equipe_4.png';
import equipe_5 from '../../assets/images/equipe_5.png';
import equipe_6 from '../../assets/images/equipe_6.png';
import equipe_7 from '../../assets/images/equipe_7.png';
import equipe_8 from '../../assets/images/equipe_8.png';
import equipe_9 from '../../assets/images/equipe_9.png';
import equipe_10 from '../../assets/images/equipe_10.png';
import equipe_11 from '../../assets/images/equipe_11.png';
import equipe_12 from '../../assets/images/equipe_12.png';
import equipe_13 from '../../assets/images/equipe_13.png';
import equipe_14 from '../../assets/images/equipe_14.png';
import equipe_15 from '../../assets/images/equipe_15.png';
import equipe_16 from '../../assets/images/equipe_16.png';
import equipe_17 from '../../assets/images/equipe_17.png';
import equipe_18 from '../../assets/images/equipe_18.png';
import equipe_19 from '../../assets/images/equipe_19.png';
import equipe_20 from '../../assets/images/equipe_20.png';
import equipe_21 from '../../assets/images/equipe_21.png';

export default function NossaEquipe() {

    const title = 'Nossa Equipe';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <section className="block-blue">
                    <div className="container-fluid p-5">
                        <div className="row justify-content-center mb-5 pb-4">
                            <div className="col-md-12">
                                <h1 className="text-white text-uppercase mb-5 animate__animated animate__bounceInDown">{title}</h1>
                            </div>
                        </div>
                        <div className="row animate__animated animate__bounceInUp animate__delay-01s">
                            <div className="col-md-12">
                                <BrowserView>
                                    <Carousel
                                        className="carousel-equipe"
                                        interval={8000}
                                        transitionTime={600}
                                        autoPlay={true}
                                        infiniteLoop={true}
                                        showArrows={true}
                                        showIndicators={false}
                                        showStatus={false}
                                        showThumbs={false}
                                        stopOnHover={false}
                                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                            hasPrev && (
                                                <a onClick={onClickHandler} title={label} className="carousel-control-prev">
                                                    <FiChevronLeft />
                                                </a>
                                            )
                                        }
                                        renderArrowNext={(onClickHandler, hasNext, label) =>
                                            hasNext && (
                                                <a onClick={onClickHandler} title={label} className="carousel-control-next">
                                                    <FiChevronRight />
                                                </a>
                                            )
                                        }
                                    >
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_2})`}} />
                                                    <div className="equipe-name">Tia Thalyta - Estagiária</div>
                                                    <div className="equipe-about">
                                                        É muito amiga de suas duas irmãs.
                                                        Cursa Pedagogia na UERJ.
                                                        É líder de adolescente e de GCEU. 
                                                        Ama malhar e ler.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_3})`}} />
                                                    <div className="equipe-name">Tia Aline - Coordenadora </div>
                                                    <div className="equipe-about">
                                                        Família é seu tudo.                                                                                                                                                 
                                                        Formada e pós-graduada, está buscando se aprofundar na área de Educação Cristã. 
                                                        Serve no Ministério de Ensino da sua Igreja.
                                                        Ama nadar.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_4})`}} />
                                                    <div className="equipe-name">Tia Edinha - Professora</div>
                                                    <div className="equipe-about">
                                                        É filha de professora e atua na área de educação há mais de 10 anos. 
                                                        Ama tudo que envolve música e adoração ao Senhor!
                                                        Sarah e Elisa, suas filhas, são um presente de Deus. 
                                                        Tem um dom para desenhar, curte muito um bom passeio e boa conversa!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_5})`}} />
                                                    <div className="equipe-name">Tia Lavínia - Estagiária</div>
                                                    <div className="equipe-about">
                                                        Noiva do Guilherme, filha do Anderson e da Flávia.  
                                                        Graduanda em pedagogia pela UCP. 
                                                        É ministra de louvor e regente dos jovens em sua igreja. 
                                                        Ama jazz e balé clássico.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_7})`}} />
                                                    <div className="equipe-name">Tia Regiane – Secretária</div>
                                                    <div className="equipe-about">
                                                        Esposa do Beto e mamãe do Heitor. 
                                                        Um belo sorriso no rosto é sua marca registrada. 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_8})`}} />
                                                    <div className="equipe-name">Tia Patrícia - Estagiária</div>
                                                    <div className="equipe-about">
                                                        Casada com pastor e tem uma filha de 3 anos muito esperta.
                                                        Iniciou sua faculdade no segundo semestre de 2021.
                                                        Serve no ministério infantil desde que conheceu Jesus.
                                                        Ama arte, curte livros de romances e séries coreanas.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_10})`}} />
                                                    <div className="equipe-name">Tia Cris – Professora</div>
                                                    <div className="equipe-about">
                                                        Tem uma linda filha de 6 anos chamada Isabelle.
                                                        Trabalha na educação há mais de 10 anos.
                                                        Adora ao Senhor com sua voz.
                                                        Ama viajar com sua família.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_13})`}} />
                                                    <div className="equipe-name">Tia Lívia – Professora</div>
                                                    <div className="equipe-about">
                                                        Ama sua família.
                                                        Formada em pedagogia e ama a educação por princípios
                                                        Ministra de louvor em sua igreja. 
                                                        Ama ouvir músicas.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_14})`}} />
                                                    <div className="equipe-name">Tia Cíntia</div>
                                                    <div className="equipe-about">
                                                        Casada com Marcelo e mãe da Jaciara. 
                                                        Aprendeu a cozinhar bem novinha com sua mãe Eliete.
                                                        É diaconisa na casa do Senhor e serve no departamento de pré-adolescentes.
                                                        Amo cozinhar e seu passatempo preferido é descansar e passear com sua família.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_15})`}} />
                                                    <div className="equipe-name">Tia Camila – Professora</div>
                                                    <div className="equipe-about">
                                                        Casada e mamãe de um príncipe. 
                                                        Trabalha na educação desde 2013.
                                                        Seu prazer é servir Jesus. 
                                                        Gosta de passear e assistir séries.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_16})`}} />
                                                    <div className="equipe-name">Tia Lilian - Psicopedagoga</div>
                                                    <div className="equipe-about">
                                                        Tem 2 filhos lindos: Pedro Henrique e Sarah. 
                                                        É Pós-Graduada em Psicopedagogia.
                                                        Missionária na Igreja Metodista Wesleyana, ama servir a Jesus acima de qualquer coisa ou pessoa.
                                                        Ama comer, fazer comida japonesa e fotografar.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_18})`}} />
                                                    <div className="equipe-name">Tia Pri - Professora</div>
                                                    <div className="equipe-about">
                                                        Filha do Luiz e da Vera e irmã do Patrick.
                                                        Professora há mais de dez anos.
                                                        Fez pedagogia por causa do seu envolvimento com o ministério infantil.
                                                        Gosta de conhecer lugares novos e experimentar comidas diferentes.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_19})`}} />
                                                    <div className="equipe-name">Tia Roberta - Apoio</div>
                                                    <div className="equipe-about">
                                                        Casada com Rogério e mãe do Rômulo.
                                                        Gosta muito do trabalho de realiza na escola. 
                                                        Ama trabalhar com crianças e na igreja serve no ministério infantil. 
                                                        Gosta de passear ao ar livre e assistir filmes em família.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_20})`}} />
                                                    <div className="equipe-name">Tio David – Estagiário</div>
                                                    <div className="equipe-about">
                                                        Filho do Giovane e Luciana e irmão do Tiago.
                                                        Formado em psicologia e trabalha a mais de 5 anos com educação. 
                                                        É filho de pastor e ama servir a igreja. 
                                                        Não dispensa um futebol.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_21})`}} />
                                                    <div className="equipe-name">Tio Anderson – Apoio</div>
                                                    <div className="equipe-about">
                                                        Ama sua família. 
                                                        Muito dedicado em estudar e aprender. 
                                                        Está sempre disponível para servir, seja onde for. 
                                                        Filmes de ação são os seus favoritos.  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel>
                                </BrowserView>

                                <MobileView>
                                <Carousel
                                        className="carousel-equipe"
                                        interval={8000}
                                        transitionTime={600}
                                        autoPlay={true}
                                        infiniteLoop={true}
                                        showArrows={true}
                                        showIndicators={false}
                                        showStatus={false}
                                        showThumbs={false}
                                        stopOnHover={false}
                                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                            hasPrev && (
                                                <a onClick={onClickHandler} title={label} className="carousel-control-prev">
                                                    <FiChevronLeft />
                                                </a>
                                            )
                                        }
                                        renderArrowNext={(onClickHandler, hasNext, label) =>
                                            hasNext && (
                                                <a onClick={onClickHandler} title={label} className="carousel-control-next">
                                                    <FiChevronRight />
                                                </a>
                                            )
                                        }
                                    >
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_2})`}} />
                                                    <div className="equipe-name">Tia Thalyta - Estagiária</div>
                                                    <div className="equipe-about">
                                                        É muito amiga de suas duas irmãs.
                                                        Cursa Pedagogia na UERJ.
                                                        É líder de adolescente e de GCEU. 
                                                        Ama malhar e ler.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_3})`}} />
                                                    <div className="equipe-name">Tia Aline - Coordenadora </div>
                                                    <div className="equipe-about">
                                                        Família é seu tudo.                                                                                                                                                 
                                                        Formada e pós-graduada, está buscando se aprofundar na área de Educação Cristã. 
                                                        Serve no Ministério de Ensino da sua Igreja.
                                                        Ama nadar.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_4})`}} />
                                                    <div className="equipe-name">Tia Edinha - Professora</div>
                                                    <div className="equipe-about">
                                                        É filha de professora e atua na área de educação há mais de 10 anos. 
                                                        Ama tudo que envolve música e adoração ao Senhor!
                                                        Sarah e Elisa, suas filhas, são um presente de Deus. 
                                                        Tem um dom para desenhar, curte muito um bom passeio e boa conversa!
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_5})`}} />
                                                    <div className="equipe-name">Tia Lavínia - Estagiária</div>
                                                    <div className="equipe-about">
                                                        Noiva do Guilherme, filha do Anderson e da Flávia.  
                                                        Graduanda em pedagogia pela UCP. 
                                                        É ministra de louvor e regente dos jovens em sua igreja. 
                                                        Ama jazz e balé clássico.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_7})`}} />
                                                    <div className="equipe-name">Tia Regiane – Secretária</div>
                                                    <div className="equipe-about">
                                                        Esposa do Beto e mamãe do Heitor. 
                                                        Um belo sorriso no rosto é sua marca registrada. 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_8})`}} />
                                                    <div className="equipe-name">Tia Patrícia - Estagiária</div>
                                                    <div className="equipe-about">
                                                        Casada com pastor e tem uma filha de 3 anos muito esperta.
                                                        Iniciou sua faculdade no segundo semestre de 2021.
                                                        Serve no ministério infantil desde que conheceu Jesus.
                                                        Ama arte, curte livros de romances e séries coreanas.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_10})`}} />
                                                    <div className="equipe-name">Tia Cris – Professora</div>
                                                    <div className="equipe-about">
                                                        Tem uma linda filha de 6 anos chamada Isabelle.
                                                        Trabalha na educação há mais de 10 anos.
                                                        Adora ao Senhor com sua voz.
                                                        Ama viajar com sua família.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_13})`}} />
                                                    <div className="equipe-name">Tia Lívia – Professora</div>
                                                    <div className="equipe-about">
                                                        Ama sua família.
                                                        Formada em pedagogia e ama a educação por princípios
                                                        Ministra de louvor em sua igreja. 
                                                        Ama ouvir músicas.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_14})`}} />
                                                    <div className="equipe-name">Tia Cíntia</div>
                                                    <div className="equipe-about">
                                                        Casada com Marcelo e mãe da Jaciara. 
                                                        Aprendeu a cozinhar bem novinha com sua mãe Eliete.
                                                        É diaconisa na casa do Senhor e serve no departamento de pré-adolescentes.
                                                        Amo cozinhar e seu passatempo preferido é descansar e passear com sua família.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_15})`}} />
                                                    <div className="equipe-name">Tia Camila – Professora</div>
                                                    <div className="equipe-about">
                                                        Casada e mamãe de um príncipe. 
                                                        Trabalha na educação desde 2013.
                                                        Seu prazer é servir Jesus. 
                                                        Gosta de passear e assistir séries.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_16})`}} />
                                                    <div className="equipe-name">Tia Lilian - Psicopedagoga</div>
                                                    <div className="equipe-about">
                                                        Tem 2 filhos lindos: Pedro Henrique e Sarah. 
                                                        É Pós-Graduada em Psicopedagogia.
                                                        Missionária na Igreja Metodista Wesleyana, ama servir a Jesus acima de qualquer coisa ou pessoa.
                                                        Ama comer, fazer comida japonesa e fotografar.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_18})`}} />
                                                    <div className="equipe-name">Tia Pri - Professora</div>
                                                    <div className="equipe-about">
                                                        Filha do Luiz e da Vera e irmã do Patrick.
                                                        Professora há mais de dez anos.
                                                        Fez pedagogia por causa do seu envolvimento com o ministério infantil.
                                                        Gosta de conhecer lugares novos e experimentar comidas diferentes.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_19})`}} />
                                                    <div className="equipe-name">Tia Roberta - Apoio</div>
                                                    <div className="equipe-about">
                                                        Casada com Rogério e mãe do Rômulo.
                                                        Gosta muito do trabalho de realiza na escola. 
                                                        Ama trabalhar com crianças e na igreja serve no ministério infantil. 
                                                        Gosta de passear ao ar livre e assistir filmes em família.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_20})`}} />
                                                    <div className="equipe-name">Tio David – Estagiário</div>
                                                    <div className="equipe-about">
                                                        Filho do Giovane e Luciana e irmão do Tiago.
                                                        Formado em psicologia e trabalha a mais de 5 anos com educação. 
                                                        É filho de pastor e ama servir a igreja. 
                                                        Não dispensa um futebol.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="equipe-container">
                                                    <img className="equipe-image" src={base} style={{backgroundImage: `url(${equipe_21})`}} />
                                                    <div className="equipe-name">Tio Anderson – Apoio</div>
                                                    <div className="equipe-about">
                                                        Ama sua família. 
                                                        Muito dedicado em estudar e aprender. 
                                                        Está sempre disponível para servir, seja onde for. 
                                                        Filmes de ação são os seus favoritos.  
                                                    </div>
                                                </div>
                                            </div>
                                    </Carousel>
                                </MobileView>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    );
}