import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { FaPhone, FaMapMarkerAlt, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import InputMask from "react-input-mask";
import api from '../../services/api';
import shape11 from '../../assets/images/shape11.png';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

export default function Contato() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    function reset(){
        setName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setMessage('');
        setLoading(false);
        setError(false);
        setErrorMessage('');
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState(false);

    async function submitContact(e){
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        try{
            if(name==="" || email==="" || phone==="" || subject==="" || message===""){
                setLoading(false);
                setError(true);
                setErrorMessage("Todos os campos devem ser preenchidos!");
                return;
            }

            const respose = await api.post('/wesleyano-contato', {
                name,
                email,
                phone,
                subject,
                message
            });
            
            setSuccess(true);
            setSuccessMessage("Mensagem enviada com sucesso!");
            reset();

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage("Erro ao enviar mensagem! Tente novamente mais tarde!");
        }
    }

    const title = 'Contato';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <PageHeader title={title} />
                <section className="contato">
                    <div className="container">
                        <div className={`row ${ loading ? 'loading-sending' : '' }`}>
                            <div className="col-md-7 animate__animated animate__bounceInUp">
                                <h4 className="mb-3">Envie-nos uma mensagem</h4>
                                {success &&
                                <div className="alert alert-success">
                                    {success_message}
                                </div>}
                                {error &&
                                <div className="alert alert-danger">
                                    {error_message}
                                </div>}
                                <form onSubmit={submitContact}>
                                    <div className="row">
                                    <div className="form-group col-12">
                                            <input className="form-control" placeholder="Nome do Responsável" value={name} onChange={e => setName(e.target.value)} type="text" />
                                        </div>
                                        <div className="form-group col-12">
                                            <input className="form-control" placeholder="E-mail" value={email} onChange={e => setEmail(e.target.value)} type="email" />
                                        </div>
                                        <div className="form-group col-12">
                                            <InputMask className="form-control" mask="(99) 99999-9999" placeholder="Celular" value={phone} onChange={e => setPhone(e.target.value)} type="tel" />
                                        </div>
                                        <div className="form-group col-12">
                                            <input className="form-control" placeholder="Assunto" value={subject} onChange={e => setSubject(e.target.value)} type="text" />
                                        </div>
                                        <div className="form-group col-12">
                                            <textarea rows="5" className="form-control" placeholder="Mensagem" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <button type="submit" className="btn btn-default" name="submit">ENVIAR</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4 sidebar animate__animated animate__bounceInUp animate__delay-01s">
                                <div className="widget widget_categories">
                                    <h5 className="widget_title">Informações</h5>
                                    <ul className="contact_info list_nonemb-2">
                                        <li>
                                            <FaMapMarkerAlt /> Av. Benjamin Constant, 280 - Centro, Petrópolis, RJ, 25610-130
                                        </li>
                                        <li>
                                            <FaEnvelope /> contato@institutowesleyano.org
                                        </li>
                                        <li>
                                            <FaWhatsapp size={20} /> <a href="https://wa.me/5524993197918" target="_blank">(24) 99319-7918</a>
                                        </li>
                                        <li>
                                            <FaPhone /> (24) 2244-8226
                                        </li>
                                    </ul>

                                    <h5 className="widget_title pt-3 mt-3" style={{display:'none'}}>Horários:</h5>
                                    <ul className="contact_info list_none" style={{display:'none'}}>
                                        <li><span><strong>Segunda:</strong></span> 8:00 - 18:00</li>
                                        <li><span><strong>Terça:</strong></span> 8:00 - 18:00</li>
                                        <li><span><strong>Quarta:</strong></span> 8:00 - 18:00</li>
                                        <li><span><strong>Quinta:</strong></span> 8:00 - 18:00</li>
                                        <li><span><strong>Sexta:</strong></span> 8:00 - 18:00</li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="ol_shape11">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-03s" y={[30, 20]} tagOuter="figure">
                            <img src={shape11} />
                        </Parallax>
                    </div>
                </section>
                <section className="mt-0 pt-0 mb-0 pb-0 animate__animated animate__bounceInUp animate__delay-03s">
                    <div className="contact_map map_radius_rtrb overflow-hidden">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.9209744041314!2d-43.16640603593362!3d-22.5071479694481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x99a807d27703a5%3A0x6674c12ad612aa44!2sAv.%20Benjamin%20Constant%2C%20280%20-%20Centro%2C%20Petr%C3%B3polis%20-%20RJ!5e0!3m2!1spt-BR!2sbr!4v1628987069576!5m2!1spt-BR!2sbr" style={{border:0}} allowFullScreen></iframe>
                    </div>
                </section>
            <Footer />
        </>
    );
}