import React, { useEffect } from 'react';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

export default function Politica() {

    const title = 'Política de Privacidade';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <PageHeader title={title} />
                <section className="quem_somos">
                    <div className="container">
                        <div className="row animate__animated animate__bounceInUp">
                            <div className="col-md-8 m-auto">

                                <h2><strong>Objetivo</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">Fixar claramente as regras de obten&ccedil;&atilde;o, acesso, uso, armazenamento, compartilhamento, transfer&ecirc;ncia, enriquecimento dos dados coletados dos usu&aacute;rios, al&eacute;m dos registros de suas atividades, de acordo com as leis em vigor no Brasil, especialmente a Constitui&ccedil;&atilde;o Federal e o <strong><a target="_blank" href="http://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2014/Lei/L12965.htm">Marco Civil</a></strong> da Internet.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Import&acirc;ncia do direito &agrave; privacidade</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">A privacidade &eacute; um dos direitos mais importantes para o indiv&iacute;duo, est&aacute; protegida pela lei brasileira e consiste na habilidade que este tem de controlar a exposi&ccedil;&atilde;o de informa&ccedil;&otilde;es sobre sua vida pessoal, sua intimidade, bem como a disponibilidade de dados sobre si mesmo, de retificar, ratificar ou apagar estes e de proteger a confidencialidade de suas comunica&ccedil;&otilde;es, seu domic&iacute;lio, sua imagem, honra e reputa&ccedil;&atilde;o perante terceiros.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Quando voc&ecirc; aceita essa Pol&iacute;tica de Privacidade, confere sua livre e expressa concord&acirc;ncia com os termos aqui estipulados, diretamente e/ou por meio de seu respons&aacute;vel legal, que deve estar ciente tamb&eacute;m das regras aqui estabelecidas.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Aplica&ccedil;&atilde;o</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">Esta Pol&iacute;tica ser&aacute; aplicada todas as vezes que voc&ecirc; acessar e/ou interagir com o Site e/ou App Mobile do Instituto Wesleyano de Ensino ou com outros ambientes que possam recepcionar seus dados, tais como formul&aacute;rios preenchidos e entregues para a Institui&ccedil;&atilde;o, assim como toda a documenta&ccedil;&atilde;o administrativa e abranger&aacute; todas as funcionalidades disponibilizadas neste. No caso de servi&ccedil;os ofertados pela Institui&ccedil;&atilde;o em outras plataformas, esta Pol&iacute;tica ser&aacute; aplicada de modo complementar ao da pr&oacute;pria plataforma.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Prop&oacute;sito da coleta, uso e armazenamento das informa&ccedil;&otilde;es do usu&aacute;rio</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">Os prop&oacute;sitos da coleta, uso e armazenamento das suas informa&ccedil;&otilde;es como usu&aacute;rio do Site e/ou App Mobile do Instituto Wesleyano de Ensino s&atilde;o: garantir a sua seguran&ccedil;a e da Institui&ccedil;&atilde;o;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- atender adequadamente &agrave;s solicita&ccedil;&otilde;es e d&uacute;vidas dos usu&aacute;rios;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- mant&ecirc;-las, para fins de guarda como acervo permanente da Institui&ccedil;&atilde;o para registro das atividades educacionais e acad&ecirc;micas;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- contribuir para melhorias cont&iacute;nuas das plataformas e dos servi&ccedil;os educacionais prestados pelo Instituto Wesleyano de Ensino, notadamente quanto &agrave; usabilidade, navegabilidade e interatividade, sempre buscando melhores experi&ecirc;ncias para os usu&aacute;rios;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- manter atualizados os cadastros dos seus dados para fins de contato por telefone fixo, celular, correio eletr&ocirc;nico, SMS, mala direta, redes sociais ou por outros meios de comunica&ccedil;&atilde;o existentes;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- efetuar estat&iacute;sticas, estudos, pesquisas e levantamento pertinentes &agrave; atividade de ensino mediante o comportamento dos usu&aacute;rios;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- promover os servi&ccedil;os da Institui&ccedil;&atilde;o e seus parceiros, al&eacute;m de informar sobre novidades, conte&uacute;dos e demais informa&ccedil;&otilde;es relevantes para a manuten&ccedil;&atilde;o do relacionamento com a Institui&ccedil;&atilde;o;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- colaborar e/ou cumprir ordem judicial ou requisi&ccedil;&atilde;o por autoridade.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">A base de dados formada por meio da coleta de dados no Site e/ou App Mobile do Instituto Wesleyano de Ensino &eacute; de propriedade e responsabilidade da Institui&ccedil;&atilde;o e n&atilde;o ser&aacute; comercializada e/ou alugada para terceiros, sendo seu acesso ou compartilhamento, quando necess&aacute;rios, feitos dentro dos limites e prop&oacute;sitos educacionais e acad&ecirc;micos.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Os dados poder&atilde;o ser informados &agrave;s institui&ccedil;&otilde;es vinculadas ao Instituto Wesleyano de Ensino, ao Minist&eacute;rio da Educa&ccedil;&atilde;o (MEC) e &agrave;s entidades que tenham como miss&atilde;o promover a melhoria da Educa&ccedil;&atilde;o no Brasil. Internamente, os dados poder&atilde;o ser acessados por profissionais devidamente autorizados pelo Instituto Wesleyano de Ensino, respeitando os princ&iacute;pios de proporcionalidade e necessidade, relev&acirc;ncia para os objetivos e prop&oacute;sitos educacionais e acad&ecirc;micos, tal qual o compromisso de confidencialidade e preserva&ccedil;&atilde;o da privacidade.<br /><br /></p>

                                <h2><strong>Gest&atilde;o e seguran&ccedil;a das informa&ccedil;&otilde;es do Usu&aacute;rio</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">Seus dados s&atilde;o obtidos por meio da efetiva&ccedil;&atilde;o de seu v&iacute;nculo com a Institui&ccedil;&atilde;o, quando voc&ecirc; insere as suas informa&ccedil;&otilde;es voluntariamente, por meio de ferramentas de coleta de dados de acesso e navega&ccedil;&atilde;o existentes em algum dos nossos Portais.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">O Instituto Wesleyano de Ensino n&atilde;o &eacute; respons&aacute;vel pela veracidade ou falta dela nas informa&ccedil;&otilde;es prestadas por seus usu&aacute;rios ou pela desatualiza&ccedil;&atilde;o destas, sendo responsabilidade do usu&aacute;rio atualiz&aacute;-las. Os dados coletados s&atilde;o armazenados em ambiente seguro.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Voc&ecirc; est&aacute; ciente de que os seus dados obtidos pelo Site e/ou App Mobile do Instituto Wesleyano de Ensino poder&atilde;o ser armazenados em servidor pr&oacute;prio ou de terceiro contratado para este fim, sejam eles alocados no Brasil ou fora do pa&iacute;s.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Registro de Atividades</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">O Instituto Wesleyano de Ensino registra todas as atividades que voc&ecirc; efetua em nosso ambiente, por meio de logs, incluindo:</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- endere&ccedil;o IP;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- a&ccedil;&otilde;es efetuadas no ambiente;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- informa&ccedil;&otilde;es sobre o dispositivo utilizado, como vers&atilde;o de sistema operacional, navegador, e demais dados poss&iacute;veis de serem coletados;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- cookies.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Prazo de guarda e gerenciamento dos dados pelo usu&aacute;rio</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">Importante esclarecer que, mesmo que voc&ecirc; deixe de ter um relacionamento conosco, seus dados pessoais, de acesso e de navega&ccedil;&atilde;o poder&atilde;o ser mantidos pelo prazo m&iacute;nimo de 6 (seis) meses, estendido por prazo maior nas hip&oacute;teses que assim a lei estabelecer para fins de auditoria e preserva&ccedil;&atilde;o de direitos, ou, ainda, de forma permanente, quando houver previs&atilde;o de guarda para fins de acervo hist&oacute;rico para o Instituto Wesleyano de Ensino.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Os dados podem ser exclu&iacute;dos, por sua solicita&ccedil;&atilde;o direta, ou no caso de menor de idade, por meio de seu respons&aacute;vel legal, conforme determina o Marco Civil da Internet, desde que decorrido o prazo prescricional relacionado &agrave;s provas a que seus registros possam estar vinculados e desde que n&atilde;o sejam de guarda permanente (acervo hist&oacute;rico). Esse prazo ser&aacute; contado a partir do encerramento definitivo de sua rela&ccedil;&atilde;o com o Instituto Wesleyano de Ensino.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">A exibi&ccedil;&atilde;o, retifica&ccedil;&atilde;o e ratifica&ccedil;&atilde;o desses dados tamb&eacute;m poder&atilde;o ser solicitadas a qualquer tempo por voc&ecirc;, exceto no caso de menores de idade, em que o respons&aacute;vel legal deve faz&ecirc;-lo, observado o item acima. Para que os pedidos acima sejam recebidos, voc&ecirc; deve entrar em contato atrav&eacute;s dos meios disponibilizados na op&ccedil;&atilde;o de Fale Conosco.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Disposi&ccedil;&otilde;es finais</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">As disposi&ccedil;&otilde;es constantes desta Pol&iacute;tica de Privacidade est&atilde;o sujeitas &agrave; melhoria e ao aprimoramento cont&iacute;nuos e podem ser atualizadas ou modificadas a qualquer momento, de forma unilateral ou em raz&atilde;o de lei, cabendo a voc&ecirc;, ou a seu respons&aacute;vel legal, caso voc&ecirc; seja menor de idade, o dever de ci&ecirc;ncia de seu teor no momento do acesso e navega&ccedil;&atilde;o do Site e/ou App Mobile do Instituto Wesleyano de Ensino. Recomendamos que voc&ecirc; realize a leitura peri&oacute;dica desta Pol&iacute;tica, para fins de atualiza&ccedil;&atilde;o e conhecimento de seus direitos e obriga&ccedil;&otilde;es.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Caso seja necess&aacute;ria a utiliza&ccedil;&atilde;o de empresas terceirizadas para realizar o processamento de quaisquer dados disponibilizados no Site e/ou App Mobile do Instituto Wesleyano de Ensino, estas dever&atilde;o, obrigatoriamente, respeitar as regras estipuladas nessa Pol&iacute;tica de Privacidade e em nossas normas de Seguran&ccedil;a da Informa&ccedil;&atilde;o.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Caso alguma disposi&ccedil;&atilde;o desta Pol&iacute;tica de Privacidade seja considerada ilegal ou ileg&iacute;tima por autoridade da localidade em que voc&ecirc; reside ou da sua conex&atilde;o, as demais condi&ccedil;&otilde;es permanecer&atilde;o em pleno vigor e efeito.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Cookies</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">Queremos oferecer a melhor experi&ecirc;ncia durante a sua navega&ccedil;&atilde;o em nossos sites e tamb&eacute;m na internet.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Assim, podemos usar cookies e coletar, tratar, armazenar e/ou compartilhar - entre as empresas do Instituto Wesleyano de Ensino e outros parceiros - informa&ccedil;&otilde;es de sua navega&ccedil;&atilde;o, para:</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- garantir maior seguran&ccedil;a durante a sua navega&ccedil;&atilde;o;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- aperfei&ccedil;oar sua usabilidade, experi&ecirc;ncia e interatividade na utiliza&ccedil;&atilde;o dos nossos portais, sites, aplicativos e durante a sua navega&ccedil;&atilde;o na internet;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- fazer ofertas e/ou a dar voc&ecirc; informa&ccedil;&otilde;es mais assertivas e relevantes &agrave;s suas necessidades e interesses;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- buscar maior efici&ecirc;ncia em rela&ccedil;&atilde;o &agrave; frequ&ecirc;ncia e continuidade da nossa comunica&ccedil;&atilde;o com voc&ecirc;;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">- realizar pesquisas de comunica&ccedil;&atilde;o e marketing de relacionamento, para melhorar nossos produtos e servi&ccedil;os, bem como apura&ccedil;&atilde;o de estat&iacute;sticas em geral.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Lembramos que voc&ecirc; pode, a qualquer momento, ativar em seu navegador mecanismos para inform&aacute;-lo quando estes estiverem acionados ou, ainda, para impedir que sejam.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Quais tipos de cookies que utilizamos?</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">Cookies s&atilde;o arquivos criados pelos sites que, enquanto voc&ecirc; navega na internet, s&atilde;o armazenados em seu navegador e ajudam a personalizar seu acesso.</p>

                                <p className="f-17 mb-3 pb-3 text-justify"><strong>Cookies de prefer&ecirc;ncias</strong> - Estes cookies permitem que nossos sites lembrem informa&ccedil;&otilde;es que mudam a forma como o site se comporta ou &eacute; exibido, como o idioma preferido ou a regi&atilde;o em que o usu&aacute;rio est&aacute;.</p>

                                <p className="f-17 mb-3 pb-3 text-justify"><strong>Cookies de sess&atilde;o</strong> - Estes cookies s&atilde;o usados &ldquo;na sess&atilde;o&rdquo;, a cada vez que voc&ecirc; visita, e expiram em seguida, quando voc&ecirc; deixa um site ou logo depois. N&atilde;o s&atilde;o armazenados no seu dispositivo permanentemente, n&atilde;o cont&ecirc;m dados pessoais e ajudam a minimizar a necessidade de transferir dados pessoais pela internet. Esses cookies podem ser exclu&iacute;dos ou voc&ecirc; pode se recusar a permitir o uso, mas isso prejudicar&aacute; o desempenho e a sua experi&ecirc;ncia no uso dos sites. Os cookies tamb&eacute;m utilizam registros de hora, que marcam quando voc&ecirc; acessa e quando voc&ecirc; sai de um site.</p>

                                <p className="f-17 mb-3 pb-3 text-justify"><strong>Cookies de rastreamento</strong> - Estes cookies permitem o reconhecimento de visitantes que retornam aos nossos sites. Ao combinar um identificador an&ocirc;nimo gerado aleatoriamente, o cookie de rastreamento mant&eacute;m o controle sobre o local de onde veio o usu&aacute;rio dos nossos sites, o mecanismo de busca que pode ter usado, o link em que clicou, a senha usada e sua localiza&ccedil;&atilde;o geogr&aacute;fica ao acessar um site. Ao monitorar esses dados, podemos fazer melhorias em nossos sites.</p>

                                <p className="f-17 mb-3 pb-3 text-justify"><strong>Cookies anal&iacute;ticos</strong> - Cookies de desempenho s&atilde;o utilizados para analisar a forma como os sites s&atilde;o usados e para monitorar seu desempenho, o que nos permite melhorar a sua experi&ecirc;ncia no uso dos sites. Estes cookies ajudam-nos a adaptar os conte&uacute;dos para refletir o que os usu&aacute;rios consideram mais interessante e identificar quando surgem problemas t&eacute;cnicos. Tamb&eacute;m podemos usar esses dados para compilar relat&oacute;rios que nos ajudam a analisar como os sites s&atilde;o utilizados, quais s&atilde;o os problemas mais comuns e como podemos melhor&aacute;-los.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Voc&ecirc; pode desativar os cookies atrav&eacute;s das prefer&ecirc;ncias do seu navegador. Sem eles sua navega&ccedil;&atilde;o pode se tornar limitada e algumas funcionalidades dos sites podem ficar comprometidas.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Como alterar e/ou excluir informa&ccedil;&otilde;es pessoais</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">Para alterar informa&ccedil;&otilde;es pessoais ou mesmo exclu&iacute;-las de nosso sistema, basta entrar em contato com o <strong><a target="_blank" href="https://institutowesleyano.org/contato">Fale Conosco</a></strong>. Contudo, lembramos que ao preencher qualquer formul&aacute;rio novamente, os seus dados ser&atilde;o reinseridos, automaticamente, na lista de contatos. Portanto, se desejar, dever&aacute; solicitar novamente a retirada de seus dados. &nbsp;</p>

                                <p className="f-17 mb-3 pb-3 text-justify">&nbsp;</p>

                                <h2><strong>Atualiza&ccedil;&otilde;es</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify">A presente Pol&iacute;tica de Privacidade pode passar por atualiza&ccedil;&otilde;es. Dessa forma, recomendamos que visite, periodicamente, esta p&aacute;gina para que tenha conhecimento das altera&ccedil;&otilde;es.</p>

                                <p className="f-17 mb-3 pb-3 text-justify">Para mais informa&ccedil;&otilde;es sobre a nossa pol&iacute;tica de privacidade, pedimos que entre em contato com nosso <strong><a target="_blank" href="https://institutowesleyano.org/contato">Fale Conosco</a></strong>.<br /><br /></p>
                                <h2><strong>Gloss&aacute;rio</strong></h2>

                                <p className="f-17 mb-3 pb-3 text-justify"><strong>IP</strong>: Abreviatura de Internet Protocol. &Eacute; um conjunto de n&uacute;meros que identifica o dispositivo dos usu&aacute;rios na internet.</p>

                                <p className="f-17 mb-3 pb-3 text-justify"><strong>Logs</strong>: Registros de atividades dos usu&aacute;rios efetuadas nos Portais.</p>

                                <p className="f-17 mb-3 pb-3 text-justify"><strong>Cookies</strong>: Arquivos enviados pelo servidor do Portal para o computador dos usu&aacute;rios, com a finalidade de identificar o computador e obter dados de acesso, como p&aacute;ginas navegadas ou links clicados, permitindo, desta forma, personalizar a navega&ccedil;&atilde;o dos Usu&aacute;rios nos Portais, de acordo com o seu perfil.</p>

                                <p className="f-17 mb-3 pb-3 text-justify"><strong>Instituto Wesleyano de Ensino</strong>, inscrita no CNPJ sob o n&ordm; 44.035.868/0001-00 com sede na Avenida Benjamim Constant, 280 &ndash; Centro &ndash; CEP 25610-130 na cidade de Petr&oacute;polis, Estado do Rio de Janeiro, entidade detentora dos direitos das aplica&ccedil;&otilde;es.</p>

                            </div>
                        </div>
                    </div>
                    
                </section>
            <Footer />
        </>
    );
}