import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

import shape20 from '../../assets/images/shape20.png';
import shape10 from '../../assets/images/shape10.png';
import shape15 from '../../assets/images/shape15.png';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

import missao from '../../assets/images/missao.png';
import visao from '../../assets/images/visao.png';
import valores from '../../assets/images/valores.png';

export default function ProcessoSeletivo() {

    const title = 'Processo Seletivo';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <PageHeader title={title} />
                <section className="bg_gray">
                    <div className="container">
                        <div className="row justify-content-center mb-4">
                            <div className="col-md-12">
                                <div className="text-justify animate__animated animate__bounceInUp">
                                    <p className="f-18">Prezado candidato, é com muita alegria que recebemos sua intenção em fazer parte da nossa equipe.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="icon_box text-center bg-white icon_box_style2 box_shadow2 radius_all_10 animate__animated animate__bounceInUp animate__delay-01s">
                                    <div className="box_icon bg_blue mb-3">
                                        <img src={missao} />
                                    </div>
                                    <div className="intro_desc">
                                        <h5 className="text_blue">NOSSA MISSÃO</h5>
                                        <p>Oferecer uma EDUCAÇÃO de QUALIDADE baseada nos ENSINAMENTOS BÍBLICOS que preservam a ÉTICA e a MORAL da sociedade.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon_box text-center bg-white icon_box_style2 box_shadow2 radius_all_10 animate__animated animate__bounceInUp animate__delay-02s">
                                    <div className="box_icon bg_default mb-3">
                                        <img src={visao} />
                                    </div>
                                    <div className="intro_desc">
                                        <h5 className="text_default">NOSSA VISÃO</h5>
                                        <p>Ser uma escola que FORME indivíduos RELEVANTES E EFETIVOS para sociedade.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon_box text-center bg-white icon_box_style2 box_shadow2 radius_all_10 animate__animated animate__bounceInUp animate__delay-03s">
                                    <div className="box_icon bg_light_green mb-3">
                                        <img src={valores} />
                                    </div>
                                    <div className="intro_desc">
                                        <h5 className="text_light_green">NOSSOS VALORES</h5>
                                        <p>AMOR E CUIDADO através da EXCELÊNCIA, HONRA E SERVIÇO.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                            <div className="col-md-12">
                                <div className="text-justify animate__animated animate__bounceInUp">
                                    <p className="f-18">Atentem-se as exigências de cada função antes de realizarem a inscrição. As inscrições serão aceitas até o dia 31/03/2022.</p>
                                    <p className="f-18">Desde já agradecemos seu interesse!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="quem_somos">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 m-auto">
                                <div className="text-justify animate__animated animate__bounceInUp animate__delay-04s">
                                    <div className="heading_s1 text-justify text-unset">
                                        <h2>FUNÇÃO: ESTAGIÁRIA</h2>
                                    </div>
                                    <p className="f-18"><strong>RESUMO DA FUNÇÃO:</strong> Ajudar no desenvolvimento de práticas pedagógicas, organização de atividades, realização de pesquisas e ministração de aulas.</p>
                                    <p className="f-18 mb-4">Carga horária: 30 horas semanais</p>
                                    
                                    <p className="f-18"><strong>EXIGÊNCIAS</strong></p>
                                    <p className="f-18"><strong>Formação:</strong> Cursando do 1º período ao 6º período em curso de nível superior, em licenciatura em pedagogia, de graduação plena, em universidades e institutos superiores de educação.</p>
                                    <p className="f-18"><strong>Experiência:</strong> Sem experiência.</p>
                                    <p className="f-18"><strong>Competências e habilidades:</strong> Trabalho em equipe, foco no aluno, organização, conhecimento de tecnologias, boa comunicação, adaptação, criatividade, atualização constante, empatia.</p>

                                    <Link to="/inscricao-estagiaria" className="btn btn-success mb-4">INSCREVER-SE</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ol_shape20">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-01s" y={[-30, 20]} tagOuter="figure">
                            <img src={shape20} />
                        </Parallax>
                    </div>
                    <div className="ol_shape10">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-02s" y={[30, 20]} tagOuter="figure">
                            <img src={shape10} />
                        </Parallax>
                    </div>
                    <div className="ol_shape15">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-03s" y={[30, 20]} tagOuter="figure">
                            <img src={shape15} />
                        </Parallax>
                    </div>
                </section>
            <Footer />
        </>
    );
}