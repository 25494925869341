import React, { useEffect } from 'react';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

export default function Termos() {

    const title = 'Termos de Uso';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <PageHeader title={title} />
                <section className="quem_somos">
                    <div className="container">
                        <div className="row animate__animated animate__bounceInUp">
                            <div className="col-md-8 m-auto">
                                
                                <h2>1. Termos</h2>
                                <p className="f-17 mb-3 pb-3 text-justify">Ao acessar ao Site/App <a href="https://institutowesleyano.org/"><strong>Instituto Wesleyano de Ensino</strong></a>, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este Site/App. Os materiais contidos neste Site/App são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.</p>
                                
                                <h2>2. Uso de Licença</h2>
                                <p className="f-17 mb-3 pb-3 text-justify">É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no Site/App <strong>Instituto Wesleyano de Ensino</strong> , apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, sob esta licença, você não pode: </p>
                                <ul className="ml-5 mb-3 pb-3 text-justify">
                                    <li>modificar ou copiar os materiais;</li>
                                    <li>usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);  </li>
                                    <li>tentar descompilar ou fazer engenharia reversa de qualquer software contido no Site/App <strong>Instituto Wesleyano de Ensino</strong>;  </li>
                                    <li>remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou  </li>
                                    <li>transferir os materiais para outra pessoa ou "espelhe" os materiais em qualquer outro servidor.</li>
                                </ul>
                                <p className="f-17 mb-3 pb-3 text-justify">Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por <strong>Instituto Wesleyano de Ensino</strong> a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.</p>
                                
                                <h2>3. Isenção de responsabilidade</h2>
                                <ul className="ml-5 mb-3 pb-3 text-justify">
                                    <li>Os materiais no Site/App da <strong>Instituto Wesleyano de Ensino</strong> são fornecidos "como estão". <strong>Instituto Wesleyano de Ensino</strong> não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos. </li>
                                    <li>Além disso, o <strong>Instituto Wesleyano de Ensino</strong> não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ou à confiabilidade do uso dos materiais em seu Site/App ou de outra forma relacionado a esses materiais ou em Site/Apps vinculados a este Site/App.</li>
                                </ul>
                                
                                <h2>4. Limitações</h2>
                                <p className="f-17 mb-3 pb-3 text-justify">Em nenhum caso o <strong>Instituto Wesleyano de Ensino</strong> ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) decorrentes do uso ou da incapacidade de usar os materiais em <strong>Instituto Wesleyano de Ensino</strong>, mesmo que <strong>Instituto Wesleyano de Ensino</strong> ou um representante autorizado da <strong>Instituto Wesleyano de Ensino</strong> tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos conseqüentes ou incidentais, essas limitações podem não se aplicar a você.</p>
                                
                                <h2>5. Precisão dos materiais</h2>
                                <p className="f-17 mb-3 pb-3 text-justify">Os materiais exibidos no Site/App da <strong>Instituto Wesleyano de Ensino</strong> podem incluir erros técnicos, tipográficos ou fotográficos. <strong>Instituto Wesleyano de Ensino</strong> não garante que qualquer material em seu Site/App seja preciso, completo ou atual. <strong>Instituto Wesleyano de Ensino</strong> pode fazer alterações nos materiais contidos em seu Site/App a qualquer momento, sem aviso prévio. No entanto, <strong>Instituto Wesleyano de Ensino</strong> não se compromete a atualizar os materiais.</p>
                                
                                <h2>6. Links</h2>
                                <p className="f-17 mb-3 pb-3 text-justify">O <strong>Instituto Wesleyano de Ensino</strong> não analisou todos os Site/Apps vinculados ao seu Site/App e não é responsável pelo conteúdo de nenhum Site/App vinculado. A inclusão de qualquer link não implica endosso por <strong>Instituto Wesleyano de Ensino</strong> do Site/App. O uso de qualquer Site/App vinculado é por conta e risco do usuário.</p>
                                
                                <h3>Modificações</h3>
                                <p className="f-17 mb-3 pb-3 text-justify">O <strong>Instituto Wesleyano de Ensino</strong> pode revisar estes termos de serviço do Site/App a qualquer momento, sem aviso prévio. Ao usar este Site/App, você concorda em ficar vinculado à versão atual desses termos de serviço.</p>
                                
                                <h3>Lei aplicável</h3>
                                <p className="f-17 mb-3 pb-3 text-justify">Estes termos e condições são regidos e interpretados de acordo com as leis do <strong>Instituto Wesleyano de Ensino</strong> e você se submete irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado ou localidade.</p>
                            
                            </div>
                        </div>
                    </div>
                    
                </section>
            <Footer />
        </>
    );
}