import React, { useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import shape7 from '../../assets/images/shape7.png';
import shape11 from '../../assets/images/shape11.png';
import shape12 from '../../assets/images/shape12.png';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

export default function AtividadesEspacos() {
    const title = 'Atividades e Espaços';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <PageHeader title={title} />
                <section>
                    <div className="container">
                        <div className="row animate__animated animate__bounceInUp animate__delay-01s">
                            <div className="col-md-8 m-auto">
                                <p className="f-17 mb-3 pb-3 text-justify">Contamos com um espaço amplo para a realização das nossas atividades, estas foram planejadas por profissionais especializados em educação e visam potencializar e instigar a aprendizagem de forma natural e dinâmica, através de momentos e encontros que desenvolvem de forma integral nossos alunos.</p>
                                <p className="f-17 text-justify"><strong className="text_blue">Atividades oferecidas:</strong></p>
                                <ul className="f-17 text-justify ml-3 pl-3">
                                    <li>Artes;</li>
                                    <li>Culinária e nutrição;</li>
                                    <li>Educação Física;</li>
                                    <li>Horta e jardinagem;</li>
                                    <li>Inglês;</li>
                                    <li>Musicalização;</li>
                                    <li>Momento Tecnológio;</li>
                                    <li>Estudo da Bíblia.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="ol_shape11">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-01s" y={[-30, 20]} tagOuter="figure">
                            <img src={shape11} />
                        </Parallax>
                    </div>
                    <div className="ol_shape12">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-02s" y={[30, 20]} tagOuter="figure">
                            <img src={shape12} />
                        </Parallax>
                    </div>
                    <div className="ol_shape7">
                        <Parallax className="staggered-animation animate__animated animate__bounceInUp animate__delay-03s" y={[30, 20]} tagOuter="figure">
                            <img src={shape7} />
                        </Parallax>
                    </div>
                </section>
            <Footer />
        </>
    );
}