import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaWhatsapp } from "react-icons/fa";

export default function EducacaoInfantil() {

    const title = 'Educação Infantil';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <section className="block-green">
                    <div className="container-fluid p-5">
                        <div className="row animate__animated animate__bounceInUp animate__delay-01s">
                            <div className="col-md-12">
                                <h1 className="text-white text-uppercase mb-5 animate__animated animate__bounceInDown">{title}</h1>
                            </div>
                            <div className="col-md-5">
                                <p className="f-26 text-white text-600 mb-3 pb-3 text-justify">Pensar em Educação infantil é projetar e construir o alicerce do futuro. É entender que este é o início de uma aprendizagem significativa de descobertas e exploração de mundo, visando à autonomia, a construção e a criatividade.</p>
                                <p className="f-26 text-white text-600 mb-3 pb-3 text-justify">Com uma proposta que combina amor, cuidado, carinho e segurança, valorizamos e reconhecemos nossas crianças por suas habilidades.</p>
                                <p className="f-26 text-white text-600 mb-3 pb-3 text-justify">Nosso espaço interno e externo foi projetado para que os nossos alunos sintam-se estimulados a desenvolver suas habilidades motoras e cognitivas de forma plena, explorando tudo o que o casarão e as suas dependências podem oferecer.</p>
                                <p className="f-26 text-white text-600 text-justify">As nossas atividades são elaboradas e desenvolvidas, através de temas geradores que buscam promover o desenvolvimento conforme os campos de experiência fundamentais para o desenvolvimento infantil, baseados na BNCC (Base Nacional Comum Curricular):</p>
                                <ul className="f-26 text-white text-600 text-left mt-5 ml-3 pl-3 mb-4">
                                    <li>Eu, o outro e o nós;</li>
                                    <li>Corpo, gestos e movimentos;</li>
                                    <li>Traços, sons, cores e formas;</li>
                                    <li>Escuta, fala, pensamento e imaginação;</li>
                                    <li>Espaço, tempo, quantidades, relações e transformações.</li>
                                </ul>
                            </div>
                            <div className="col-md-7">
                                <p className="f-30 text-white text-600 mb-0 text-center">NOSSAS TURMAS:</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">1° período: 01 ano;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">2° período: 02 anos;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">3° período: 03 anos;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">4° período: 04 anos;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">5° período: 05 anos;</p>

                                <p className="f-30 text-white text-600 mb-0 text-center mt-5">AULAS EXTRAS:</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Educação Física;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Música;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Inglês;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Culinária;</p>

                                <p className="f-30 text-white text-600 mb-0 text-center mt-5">HORÁRIOS:</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Período Integral: 07h30 às 19h;</p>
                                <p className="f-24 text-white text-600 mb-0 text-center">Período Parcial: 13h às 17h</p>

                            </div>
                            <div className="col-md-12 text-right mt-3 animate__animated animate__bounceInDown">
                                <a href="https://wa.me/5524993197918" target="_blank" className="float-whatsapp">
                                    <div>Quero mais informações!</div>
                                    <div className="icon-whatsapp"><FaWhatsapp color="#b4cd19" /></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </section>
            <Footer />
        </>
    );
}