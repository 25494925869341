import React from 'react';
import banner_pages from '../assets/images/banner_pages.jpg';

export default function PageHeader(props) {

    const { title } = props;

    return (
        <section className="page-title-light breadcrumb_section" style={{backgroundImage: `url(${banner_pages})`}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="page-title text-center animate__animated animate__flipInX">
                            <h1 dangerouslySetInnerHTML={{__html: title}} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}