import React, { useEffect } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import missao from '../../assets/images/missao.png';
import visao from '../../assets/images/visao.png';
import valores from '../../assets/images/valores.png';

export default function QuemSomos() {

    const title = 'Quem Nós Somos';
    useEffect(() => {
        document.title = `${title} - Instituto Wesleyano de Ensino`;
    }, []);

    return (
        <>
            <Header />
                <section className="block-blue">
                    <div className="container-fluid p-5">
                        <div className="row justify-content-center mb-5 pb-4">
                            <div className="col-md-12">
                                <h1 className="text-white text-uppercase mb-5 animate__animated animate__bounceInDown">{title}</h1>
                                <div className="text-center animate__animated animate__bounceInUp">
                                    <p className="f-26 text-white text-600">Inauguramos no dia 13 de Fevereiro de 2022. Somos novinhos como escola, mas carregamos a tradição e confiabilidade de uma instituição presente há mais de 50 anos na cidade imperial, com uma história de relevância e compromisso com a comunidade, a Igreja Metodista Wesleyana Central de Petrópolis.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="icon_box text-center bg-white icon_box_style2 box_shadow2 radius_all_10 animate__animated animate__bounceInUp animate__delay-01s">
                                    <div className="box_icon bg_blue mb-3">
                                        <img src={missao} />
                                    </div>
                                    <div className="intro_desc">
                                        <h5 className="text_blue">NOSSA MISSÃO</h5>
                                        <p>Oferecer uma EDUCAÇÃO de QUALIDADE baseada nos ENSINAMENTOS BÍBLICOS que preservam a ÉTICA e a MORAL da sociedade.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon_box text-center bg-white icon_box_style2 box_shadow2 radius_all_10 animate__animated animate__bounceInUp animate__delay-02s">
                                    <div className="box_icon bg_default mb-3">
                                        <img src={visao} />
                                    </div>
                                    <div className="intro_desc">
                                        <h5 className="text_default">NOSSA VISÃO</h5>
                                        <p>Ser uma escola que FORME indivíduos RELEVANTES E EFETIVOS para sociedade.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icon_box text-center bg-white icon_box_style2 box_shadow2 radius_all_10 animate__animated animate__bounceInUp animate__delay-03s">
                                    <div className="box_icon bg_light_green mb-3">
                                        <img src={valores} />
                                    </div>
                                    <div className="intro_desc">
                                        <h5 className="text_light_green">NOSSOS VALORES</h5>
                                        <p>AMOR E CUIDADO através da EXCELÊNCIA, HONRA E SERVIÇO.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer />
        </>
    );
}